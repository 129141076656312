import { useEffect, useState } from "react";

const Redirect = () => {
  const [message, setMessage] = useState("Redirecting...");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const playStoreURL = "https://play.google.com/store/apps/details?id=com.alexsteb.lingora";
    const appStoreURL = "https://apps.apple.com/us/app/lingora/id6446484535";
    const websiteURL = "https://lingora.org";

    let redirectURL = websiteURL;

    if (/android/i.test(userAgent)) {
      setMessage("Redirecting to Google Play...");
      redirectURL = playStoreURL;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setMessage("Redirecting to the App Store...");
      redirectURL = appStoreURL;
    } else {
      setMessage("Redirecting to lingora.org...");
    }

    // Function to log the click and then redirect
    const logClickAndRedirect = async () => {
      try {
        await fetch("https://eoneoservers.digital:3446/updateAdClick", { 
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ title: "arabic_ad_20250307" })
        });

        // Wait a short time to ensure the request is sent
        await new Promise(resolve => setTimeout(resolve, 100));

        // Perform the redirect
        window.location.href = redirectURL;

        // iOS Fallback: If still on this page after 3 seconds, go to lingora.org
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          setTimeout(() => {
            window.location.href = websiteURL;
          }, 3000);
        }

      } catch (error) {
        console.error("Error logging ad click:", error);
        window.location.href = redirectURL; // Redirect anyway if logging fails
      }
    };

    logClickAndRedirect(); // Call the async function

  }, []);

  return <p>{message}</p>;
};

export default Redirect;
