import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Languages from "./Components/Languages";
import Features from "./Components/Features";
import Contact from "./Components/Contact";
import Privacy from "./Components/Privacy";
import Redirect from "./Components/Redirect";
import Student from "./Components/promo/Student";

import Tos from "./Components/Tos";
import Blog from "./Components/Blog";
import { getColor } from "./Components/LanguageData";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

export const App = () => {
  getColor("hindi", 0);
  useEffect(() => {
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }, [])

  const [language, setLanguage] = useState(null);

  useEffect(() => {
  }, [language])

  const mainPage = <>  <Header language={language} setLanguage={setLanguage} />
    <Languages language={language == null ? { value: "cantonese", label: "Cantonese" } : language} setLanguage={setLanguage} />
    <Features />
    <Contact />
    <Footer /></>;

  return (
    <div className="App">
      <LingoraMetaTags />
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={mainPage} />
            <Route path="privacy" element={<Privacy />} />
	    <Route path="promo/student" element={<Student />} />
	    <Route path="download" element={<Redirect />} />
            <Route path="tos" element={<Tos />} />
            <Route path="*" element={mainPage} />
          </Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

const LingoraMetaTags = () => (
  <Helmet>
    {/* Primary Meta Tags */}
    <title>Lingora - Language Learning</title>
    <meta name="title" content="Lingora - Language Learning" />
    <meta name="description" content="Lingora helps you master a new language with detailed grammar and word-by-word breakdowns - in 500 lessons & 1000 words." />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://lingora.org/" />
    <meta property="og:title" content="Lingora - Language Learning" />
    <meta property="og:description" content="More than just flashcards and games, Lingora offers detailed grammar, vocabulary breakdowns, and advanced study tools. Learn Cantonese, Polish, Malay, and more!" />
    <meta property="og:image" content="https://lingora.org/images/promo.jpg" />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://lingora.org/" />
    <meta property="twitter:title" content="Lingora - Language Learning" />
    <meta property="twitter:description" content="Understand a new language with Lingora's detailed grammar and word-by-word breakdowns. Study real language structure for Cantonese, Polish, Malay, and more!" />
    <meta property="twitter:image" content="https://lingora.org/images/promo.jpg" />

    {/* Keywords for SEO */}
    <meta name="keywords" content="language learning, Lingora, Cantonese, Malay, Polish, Vietnamese, grammar, vocabulary, language app, tone training, script training" />

    {/* Canonical Link */}
    <link rel="canonical" href="https://lingora.org/" />

    {/* Favicon */}
    <link rel="icon" href="/favicon.ico" />
  </Helmet>
);