import React, { useState } from "react";
import ParticlesBg from "particles-bg";

const Student = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({ text: "", type: "" });

  const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  const submitEmail = async () => {
    if (!validateEmail(email)) {
      setMessage({ text: "❌ Please enter a valid email address.", type: "error" });
      return;
    }

    setMessage({ text: "", type: "" }); // Reset message

    try {
      const response = await fetch("https://eoneoservers.digital:3446/promo/generate", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, campaignType: "student" }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage({ text: "✅ Success! Your promo code has been sent.", type: "success" });
      } else {
        setMessage({ text: `❌ ${data.message || "Something went wrong."}`, type: "error" });
      }
    } catch (error) {
      setMessage({ text: "❌ Error: Could not connect to the server.", type: "error" });
    }
  };

const config = {
    body: "../images/icon_big.png",
    num: [0, 1],
    rps: 0.1,
    radius: [5, 40],
    life: [1.5, 3],
    v: [1, 2],
    tha: [0, 0],
    alpha: [0.6, 0],
    scale: [0.1, 0.2],
    position: "all", // all or center or {x:1,y:1,width:100,height:100}
    cross: "dead", // cross or bround
    random: null,  // or null,
    g: 0.2,    // gravity
    // f: [2, -1], // force
  }

  return (
<>
  <div style={styles.particlesContainer}>
    <ParticlesBg type="custom" bg={true} config={config} />
  </div>

  <div style={{ marginTop: 50, marginBottom: 100 }}>
    <div style={styles.container}>
      <h1><span style={styles.header}>🎓 Exclusive Student Promo</span></h1>
      <div style={{ lineHeight: "24px" }}>
        <p>
          Get a <span style={styles.yellow}>full year of Lingora</span> for only <span style={styles.yellow}>$11.99</span> (that’s&nbsp;just&nbsp;<span style={styles.yellow}>$1</span>&nbsp;/&nbsp;month)
        </p>

        <p style={styles.pricing}>
          <span style={styles.oldPrice}>$3.99</span> → <span style={styles.yellow}>$1</span> per month
        </p>
        <p style={styles.pricing}>
          <span style={styles.oldPrice}>$47.88</span> → <span style={styles.yellow}>$11.99</span> per year
        </p>

        <p>Enter your student&nbsp;email* below to claim your promo&nbsp;code:</p>
      </div>

      <input
        type="email"
        autoComplete="email"
        placeholder="Enter your email"
        name="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={styles.input}
        required
      />
      <button onClick={submitEmail} style={styles.button}>
        Get Your Promo Code
      </button>

      {message.text && <p style={{ ...styles.message, ...styles[message.type] }}>{message.text}</p>}

      <br />
<div style={{marginBottom: 10}}>
        <i>Get Lingora here:</i><br/>
</div>      
<a
        href="https://play.google.com/store/apps/details?id=com.alexsteb.lingora"
        target="_blank"
        rel="noopener noreferrer"
        style={styles.storeLink}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1024px-Google_Play_Store_badge_EN.svg.png"
          alt="Get it on Google Play"
          style={styles.storeImage}
        />
      </a>

      <a
        href="https://apps.apple.com/us/app/lingora/id6446484535"
        target="_blank"
        rel="noopener noreferrer"
        style={styles.storeLink}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/1024px-Download_on_the_App_Store_Badge.svg.png"
          alt="Download on the App Store"
          style={styles.storeImage}
        />
      </a>

      <br />
      <br />
      <i style={styles.note}>* Enter the email address assigned to you by your educational institution. The promo code will be sent there.</i>
      <br /><br/>
      <i style={styles.note}>Prices may vary based on your local currency and tax regulations.</i>
      <br /><br/>
      <i style={styles.note}>
        After entering the promo code in the app, you can directly choose the language courses to which you'd like to apply the promotion.
      </i>
	<br/>
	<div style={{marginTop: 30, fontSize: 13}}>
	<a href="https://lingora.org" target="_blank" rel="noreferrer">&#9658; Lingora Homepage</a>
        </div>
    </div>
</div>
</>
  );
};

// CSS-in-JS Styles
const styles = {
  particlesContainer: {
 position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: -1, // Ensures it's behind all content

  },

  container: {
    backgroundColor: "#121212",
    color: "#ffffff",
    fontFamily: "Arial, sans-serif",
    textAlign: "center",
    padding: "20px",
    maxWidth: "85%",
    width: 500,
    margin: "auto",
    paddingTop: 40,
    paddingBottom: 20,
    borderRadius: "20px",
    borderWidth: 5,
    borderStyle: "solid",
    borderColor: "#343434",
    boxShadow: "0px 0px 15px rgba(255, 255, 255, 0.1)",
    lineHeight: "12px"
  },
  header: {
    color: "#ffffff"
},
  yellow: {
    color: "#ffffcc",
    fontWeight: "bold",
    fontSize: "105%"
  },
  pricing: {
    fontSize: "19px",
    margin: "25px 0",
  },
  oldPrice: {
    color: "#ff5c5c",
    textDecoration: "line-through",
    fontSize: "16px",
  },
  input: {
    width: "100%",
    maxWidth: "360px",
    display: "block",
    margin: "10px auto",
    padding: "12px",
    borderRadius: "4px",
    fontSize: "16px",
    boxSizing: "border-box",
    border: "1px solid #444",
    backgroundColor: "#222",
    color: "white",
    outline: "none",
  },
  button: {
    width: "100%",
    maxWidth: "360px",
    display: "block",
    margin: "10px auto",
    padding: "12px",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: "bold",
    boxSizing: "border-box",
    backgroundColor: "#0f9d58",
    border: "none",
    color: "white",
    cursor: "pointer",
  },
  buttonHover: {
    backgroundColor: "#0c7b46",
  },
  message: {
    display: "block",
    margin: "10px auto",
    padding: "10px",
    borderRadius: "4px",
    fontSize: "14px",
    textAlign: "center",
    maxWidth: "360px",
  },
  success: {
    backgroundColor: "#0f9d58",
    color: "white",
  },
  error: {
    backgroundColor: "#ff5c5c",
    color: "white",
  },
  note: {
    color: "#888",
    fontSize: "9px",
  },
  storeLink: {
    textDecoration: "none",
    display: "inline-block",
    margin: "5px",
  },
  storeImage: {
    height: "40px",
    width: "auto",
    verticalAlign: "middle",
  },
};

export default Student;
